export default {
  goBack: {
    id: 'buyback_step_device_go_back',
    defaultMessage: 'Buyback',
  },
  loading: {
    id: 'buyback_step_device_loading',
    defaultMessage: 'Loading',
  },
}
